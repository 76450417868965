import { Link } from 'gatsby';
import { Layout, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = '404 Page Not Found';
  const description = 'Enhance your operations and bring your community together with Njano for FREE';
  return <SEO title={title} description={description} location={location} />;
};

const NotFoundPage = () => (
  <Layout dark logo>
    <div className="flex flex-col items-center justify-center py-24">
      <h1 className="my-2 text-center font-heading text-[8rem] font-bold text-secondary">404</h1>
      <h1 className="my-16 text-center font-heading text-[6rem] font-bold text-secondary">Page not found</h1>
      <p className="font-kanit font-extralight text-3xl text-center">
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>
        we couldn’t find what you were looking for.
        <br />
        <br />
        <Link to="/" className="bg-secondary hover:opacity-80 px-8 py-2 text-black">
          Go home
        </Link>
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
